<script setup lang="ts">
import {CmsSlot} from "@shopware-pwa/types";
import AlgoliaListSubcategories from "~/components/algolia/AlgoliaListSubcategories.vue";
import AlgoliaChildrenSubcategories from "~/components/algolia/AlgoliaChildrenSubcategories.vue";
import {ComputedRef} from "vue/dist/vue";
import {SliderElementConfig} from "@shopware-pwa/composables-next";

const props = defineProps<{
    content: CmsSlot;
}>();

const breakpoints = {
    768: {
        slidesPerView: props.content.config?.mobile?.value ?? 4
    },
    1024: {
        slidesPerView: props.content.config?.medium?.value ?? 5
    },
    1536: {
        slidesPerView: props.content.config?.desktop?.value ?? 6
    },
};

const config: ComputedRef<SliderElementConfig> = computed(() => ({
    minHeight: {
        value: "100px",
        source: "static",
    },
    minWidth: {
        value: "150px",
        source: "static",
    },
    displayMode: {
        value: "contain",
        source: "static",
    },
    navigationDots: {
        value: "outside",
        source: "static",
    },
    navigationArrows: {
        value: "outside",
        source: "static",
    },
    title: props.content.config.title,
    breakpoints: breakpoints
})) as ComputedRef<SliderElementConfig>;
const category = inject('category');
const categoryIds: string[] = [...props.content.config?.categories?.value] ?? [];
</script>
<template>
  <AlgoliaListSubcategories v-if="categoryIds && categoryIds.length > 0" :category-ids="categoryIds" :config="config" />
  <AlgoliaChildrenSubcategories v-else :category-id="category.id" :config="config" />
</template>
